import React from 'react';
import { logger, CenterWrapper } from '@/app';
import { Auth, TitleComponent } from '@/shared';
import {
  useForm,
  onUpdate,
  onMount,
  useComponentStyle,
} from '@codeleap/common';

import { CreateProductForm } from '@/layout/Product';

import { useListings } from '@/services/api';

import { useAppSelector } from '@/lib';

export type EditProps = {
  id?: Number;
}

const Edit: React.FC<EditProps> = ({ id }) => {
  const { isLoggedIn, profile } = useAppSelector((store) => store.Session);

  const {
    data: { listings, listing, listingsByQuery },
    queries: Listings,
  } = useListings();

  onUpdate(() => {
    if (isLoggedIn) {
      Listings.getDetail.send(id).then((item) => {
        Listings.getData.send(item);
      });
    }
  }, [isLoggedIn]);

  return (
    <>
      <Auth>
        <CenterWrapper>
          <TitleComponent title={'Edit a Product'} />
          {Object.keys(listing).length > 0 && (
            <CreateProductForm listing={listing} />
          )}
        </CenterWrapper>
      </Auth>
    </>
  );
};

export default Edit;
