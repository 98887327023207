import React from 'react';
import {
  Button,
  View,
  RouterPage,
  Text,
  Menu,
  variantProvider,
} from '@/app';
import { NotFound, Page } from '@/shared';
import { onUpdate } from '@codeleap/common';
import { useAppSelector } from '@/lib';
import { Listings } from '@/redux';
import { Redirect } from '@reach/router';

import ProductCreate from './_create';
import ProductDetail from './_detail';
import ProductEdit from './_edit';


const ProductPage: React.FC = () => {
  return (
    <Page
      withRouter
      basePath={'/product'}
      title='Product'
      center={false}
      renderContentWrapper={({ children, menuItems, styles }) => (
        <View variants={['fullWidth']}>{children}</View>
      )}
    >
      <ProductCreate title={'Create'} path={'/create'} />
      <ProductDetail path={':id/detail'} />
      <ProductEdit path={':id/edit'} />
      {/* <View path='/not-found' defaultPath /> */}
      <NotFound title='teste' default />
    </Page>
  );
};

export default ProductPage;
