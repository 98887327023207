/* eslint-disable max-lines */
import React, { useState } from 'react'
import {
  Button,
  logger,
  View,
  Text,
  Theme,
  variantProvider,
  CenterWrapper,
} from '@/app'
import {
  OfferModal,
  OfferCurrencyModal,
  TitleComponent,
  Carousel,
} from '@/shared'
import { useAppSelector } from '@/lib'

import { ProductDetails, ProductImage } from '@/layout/Product'
// import { ProductDetailsPlaceholder } from '@/skeletons';
import {
  CardsSkeleton,
  RowSkeleton,
  HeaderTextSkeleton,
  ProductDetailsSkeleton,
} from '@/skeletons'
import { onUpdate, onMount, useComponentStyle } from '@codeleap/common'

import { useListings } from '@/services/api'

const Detailed: React.FC = ({ id }) => {
  const {
    data: { listings, listing, listingsByQuery },
    queries: Listings,
    loading,
  } = useListings()

  const [params, setParams] = useState({})

  const { options } = useAppSelector((store) => store.Categories)

  const styles = useComponentStyle(componentStyles)

  const isSmall = Theme.hooks.down('mid')

  onMount(() => {
    Listings.getDetail.send(id).then((item) => {
      Listings.getData.send(item)
    })
  })

  onUpdate(() => {
    if (Object.keys(listing).length > 0 && listingsByQuery.profile.count < 1) {
      Listings.getBy.send({ profile: listing?.profile?.id })
    }
  }, [listing])

  onUpdate(() => {
    logger.log({ params })
    if (!loading) {
      const paramsWithoutDescription = { ...params }
      delete paramsWithoutDescription.description
      Listings.query.send(paramsWithoutDescription)
    }
  }, [params])

  const renderProfileDetail = (showIcon, text, icon, index, arr) => {
    const isLast = arr?.length - 1 === index
    const isFirst = index === 0
    return (
      <>
        {isFirst && showIcon && <Button variants={['icon']} icon={icon} />}
        <Text
          variants={[`${isLast && 'bold'}`]}
          css={[styles.text]}
          text={text}
        />
        {!isLast && showIcon && <Button variants={['icon']} icon={icon} />}
      </>
    )
  }

  const listingAttributes = listing?.attributes?.filter(
    (attribute) => attribute.selects.length > 0,
  )

  const renderAttributes = () => {
    return (
      <>
        {listingAttributes?.map((attribute, index, arr) => (
          <>
            {renderProfileDetail(
              true,
              attribute.option.title,
              'arrowRight',
              index,
              arr,
            )}
          </>
        ))}
      </>
    )
  }

  return (
    <>
      <CenterWrapper
        styles={{
          innerWrapper: styles.innerWrapper,
        }}
      >
        <TitleComponent title={listing?.title} />
        <RowSkeleton ready={Object.keys(listing).length > 0}>
          <View variants={['alignCenter', 'gap:1', 'wrap']}>
            {renderProfileDetail(true, 'Categories', 'arrowRight')}
            {renderProfileDetail(false, `${listing?.category}`)}
            {renderAttributes()}
          </View>
        </RowSkeleton>
        <ProductDetailsSkeleton ready={Object.keys(listing).length > 0}>
          <View css={styles.wrapper} variants={['fullWidth', 'gap:3']}>
            <ProductImage listing={listing} />
            <ProductDetails
              listing={listing}
              profile={listing?.profile}
              profileListings={listingsByQuery.profile}
            />
          </View>
        </ProductDetailsSkeleton>
      </CenterWrapper>
      <OfferModal id={id} listing={listing} />
      {/* <OfferCurrencyModal id={id} /> */}
    </>
  )
}

export default Detailed

const NUM_COL = 3

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  text: {
    color: theme.colors.primaryText,
  },
  cardsWrapper: {
    display: 'grid',
    gridTemplateColumns: `repeat(${NUM_COL}, calc(${100 / NUM_COL}% - ${
      (Theme.spacing.value(2) * (NUM_COL - 1)) / NUM_COL
    }px)) `,
    gap: Theme.spacing.value(2),
    [Theme.media.down('mid')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  cardsInnerWrapper: {
    width: '100%',
  },
  wrapper: {
    [Theme.media.down('mid')]: {
      flexDirection: 'column',
      height: 'unset',
    },
  },
  mapContainer: {
    position: 'relative',
    width: '100%',
    height: '400px',
  },
  map: {
    height: '400px',
    width: '100%',
    borderRadius: Theme.borderRadius.large,
    overflow: 'hidden',
    position: 'absolute',

    'div>.gm-fullscreen-control': {
      borderRadius: '8px !important',
      right: '20px !important',
      top: '20px !important',
    },

    'div>.gm-bundled-control': {
      right: '60px !important',
    },

    'div>.gm-bundled-control>div>div': {
      borderRadius: '8px !important',

      boxShadow: 'rgb(0 0 0 / 16%) 0px 0px 20px',
    },

    // zIndex: 999999,
  },
  sliderContainer: {
    height: '100%',
    '.slick-track': {
      display: 'flex',
      gridGap: Theme.spacing.value(4),
      lineHeight: 'unset !important',
    },
    '.slick-list': {
      margin: 'unset !important',
      padding: 'unset !important',
      overflow: 'unset !important',
    },
    [Theme.media.down('small')]: {
      '.slick-track': {
        gridGap: Theme.spacing.value(2),
      },
    },
  },
  innerWrapper: {
    marginTop: Theme.spacing.value(13),
    marginBottom: Theme.spacing.value(6),
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  nearbyInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
