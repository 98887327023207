import React from 'react';
import { CenterWrapper } from '@/app';
import { Auth, TitleComponent } from '@/shared';
import { CreateProductForm } from '@/layout/Product';

const Create: React.FC = (props) => {
  return (
    <>
      <Auth>
        <CenterWrapper>
          <TitleComponent title={'Create A Product'} />
          <CreateProductForm />
        </CenterWrapper>
      </Auth>
    </>
  );
};

export default Create;
